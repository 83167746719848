/* eslint-disable no-console */
import { getTypeByDomain } from '@MGPD/myasurion-shared';
import { Header } from '@MGPD/myasurion-shared/components/header';
import { ProtectedLayout } from '@MGPD/myasurion-shared/components/layout';
import { TextScript } from '@MGPD/myasurion-shared/components/localization';
import {
    AccountInfo,
    useAccount,
    useAuthToken,
    useDocumentTitle,
    useFeatureIsOn,
} from '@MGPD/myasurion-shared/hooks';
import { useConfig } from '@MGPD/myasurion-shared/modules/config';
import { isAuthenticated } from '@MGPD/myasurion-shared/utils/functions';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Link, Text } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import DocumentsPage from './pages/DocumentsPage';
import SSOConsumer from './pages/SSOConsumer';
import SSORedirect from './pages/SSORedirect';

const LandingPage = lazy(() => import('./pages/LandingPage'));
const PlanDetailsPage = lazy(() => import('./pages/PlanDetailsPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));

interface RoutePageProps {
    account: AccountInfo;
    showBack?: boolean;
    breadcrumb?: ReactNode | undefined;
    bypass?: boolean;
}

const RoutePage: React.FC<RoutePageProps & PropsWithChildren> = ({
    account,
    breadcrumb,
    children,
    bypass = false,
}) => {
    const [authtoken] = useAuthToken({});

    return (
        <ProtectedLayout
            headerComponent={
                <>
                    <Header
                        top={0}
                        authenticated={isAuthenticated(account, authtoken)}
                        account={account}
                        signOutProps={{ styleProps: { margin: '0 auto' } }}
                        zIndex={'99999'}
                    />
                    {breadcrumb && <>{breadcrumb}</>}
                </>
            }
            bypass={bypass}
        >
            {children}
        </ProtectedLayout>
    );
};

function App() {
    const [account] = useAccount({ firstName: '', lastName: '', profileId: '', userId: '' });
    const { data } = useConfig();
    const { title } = data;
    useDocumentTitle(`${title}` || 'MyAsurion 2.0');
    const isFaqEnabled = useFeatureIsOn('faq');
    const isDocumentsEnabled = useFeatureIsOn('my-documents');
    const portalType = getTypeByDomain();

    // changing favicon
    const { theme } = data;
    const {
        favicon,
        hero: { banner },
    }: Record<string, any> = theme;

    if (favicon) {
        document.getElementById('favicon')?.setAttribute('href', favicon);
    }

    const routes = [
        {
            path: '/',
            enabled: true,
            element: (
                <RoutePage account={account}>
                    <LandingPage banner={banner} />
                </RoutePage>
            ),
        },
        {
            //preventing /portal/portal/plan
            path: portalType === 'repair' ? '/plan' : '/portal/plan',
            enabled: true,
            loader: () => import('./pages/PlanDetailsPage').then((module) => module.default),
            element: (
                <RoutePage
                    account={account}
                    breadcrumb={
                        <Box
                            w={'full'}
                            color={'#000'}
                            mt={'60px'}
                            p={'16px 8px'}
                            maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
                            mx={{ base: '0px', lg: 'auto' }}
                        >
                            <Button
                                variant={'link'}
                                as={Link}
                                colorScheme="black"
                                href={'/'}
                                leftIcon={<ArrowBackIcon />}
                                color={'#000'}
                                // display={{ base: 'block', md: 'none' }}
                            >
                                <Text>
                                    <TextScript
                                        text={'Device care'}
                                        translationKey={`Subheader/${portalType}-Device care`}
                                    />
                                </Text>
                            </Button>
                        </Box>
                    }
                >
                    <Suspense>
                        <PlanDetailsPage />
                    </Suspense>
                </RoutePage>
            ),
        },
        {
            path: '/faq',
            enabled: isFaqEnabled && portalType === 'repair',
            loader: () => import('./pages/FaqPage').then((module) => module.default),
            element: (
                <RoutePage
                    account={account}
                    breadcrumb={
                        <Box
                            w={'full'}
                            color={'#000'}
                            mt={'60px'}
                            p={'16px 8px'}
                            maxW={{ base: 'initial', lg: '768px', xl: '992px', '2xl': '1280px' }}
                            mx={{ base: '0px', lg: 'auto' }}
                        >
                            <Button
                                variant={'link'}
                                as={Link}
                                colorScheme="black"
                                href={'/'}
                                leftIcon={<ArrowBackIcon />}
                                color={'#000'}
                                // display={{ base: 'block', md: 'none' }}
                            >
                                <Text>
                                    <TextScript
                                        text={'Device care'}
                                        translationKey={`Subheader/${portalType}-Device care`}
                                    />
                                </Text>
                            </Button>
                        </Box>
                    }
                    bypass={true}
                >
                    <Suspense>
                        <FaqPage />
                    </Suspense>
                </RoutePage>
            ),
        },
        {
            //preventing /portal/portal/documents
            path: portalType === 'repair' ? '/documents' : '/portal/documents',
            enabled: isDocumentsEnabled,
            loader: () => import('./pages/DocumentsPage').then((module) => module.default),
            element: (
                <RoutePage account={account}>
                    <DocumentsPage />
                </RoutePage>
            ),
        },
        {
            path: '/ssoredirect',
            enabled: true,
            loader: () => import('./pages/SSORedirect').then((module) => module.default),
            element: (
                // <RoutePage account={account}>
                <Suspense>
                    <SSORedirect />
                </Suspense>
                // </RoutePage>
            ),
        },
        {
            path: '/ssoconsumer',
            enabled: true,
            loader: () => import('./pages/SSOConsumer').then((module) => module.default),
            element: (
                // <RoutePage account={account}>
                <Suspense>
                    <SSOConsumer />
                </Suspense>
                // </RoutePage>
            ),
        },
    ];

    return (
        <Routes>
            <Route>
                {routes.map((route, i) => {
                    const { path, enabled, element, loader } = route;

                    if (enabled) {
                        return (
                            <Route
                                key={`protected-route-${i}`}
                                path={path}
                                element={element}
                                loader={loader}
                            />
                        );
                    }
                })}
            </Route>
        </Routes>
    );
}

export default App;
