/* eslint-disable no-console */
import { MyAsurionProvider } from '@MGPD/myasurion-shared/modules/config';
import { MyAsurionDevTools } from '@MGPD/myasurion-shared/modules/devtools';
import { Mixpanel } from '@MGPD/myasurion-shared/utils/mixpanel';
import ReactDOM from 'react-dom/client';

import App from './App.tsx';
import './Swiper.css';
import ChakraExtension from './modules/theme/ChakraExtension.tsx';
import Fonts from './modules/theme/Fonts.tsx';
import { printVersion } from './utils/index.tsx';

printVersion();
Mixpanel.setupMixpanel(
    import.meta.env,
    {
        partner: 'starhub',
        program: null,
    },
    null,
    false
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <MyAsurionProvider
        env={{
            baseUrl: import.meta.env.BASE_URL,
            apiBaseUrl: import.meta.env.VITE_CONFIG_API_BASE_URL,
            authorization: import.meta.env.VITE_MOCK_AUTH,
            token: import.meta.env.VITE_MOCK_TOKEN,
            apiKey: import.meta.env.VITE_API_KEY,
            hmacKey: import.meta.env.VITE_MYASURION_API_AUTH_HMAC_KEY,
            moduleId: 'myAsurionPortal',
            enableTranslations: import.meta.env.VITE_TRANSLATIONS_ENABLED == 'true',
        }}
        config={{
            env: import.meta.env.VITE_ENV,
            apiKey: import.meta.env.VITE_REMOTE_CONFIG_API_KEY,
            authDomain: import.meta.env.VITE_REMOTE_CONFIG_AUTH_DOMAIN,
            projectId: import.meta.env.VITE_REMOTE_CONFIG_PROJECT_ID,
            storageBucket: import.meta.env.VITE_REMOTE_CONFIG_STORAGE_BUCKET,
            messagingSenderId: import.meta.env.VITE_REMOTE_CONFIG_MESSAGING_SENDER_ID,
            appId: import.meta.env.VITE_REMOTE_CONFIG_APP_ID,
            measurementId: import.meta.env.VITE_REMOTE_CONFIG_MEASUREMENT_ID,
        }}
        onBoardingProps={{
            run: true,
            steps: [],
        }}
    >
        <>
            <Fonts />
            <ChakraExtension />
            <App />
            <MyAsurionDevTools enabled={import.meta.env.VITE_DEVTOOLS_ENABLED == 'true'} />
        </>
    </MyAsurionProvider>
);
